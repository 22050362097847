import Earth from "./TheEarth.jpg";
import Aco from "./Aco.png";
import UnderTree from "./UnderTheTree.png";
import SnowMountain from "./SnowyMountains.png";
import CanolaField from "./CanolaFlowerField.png";
import Starlight from "./StarlightNight.png";
import Lake from "./TheLake.png";
import Sea from "./TheSea.png";
import Valley from "./TheValley.png";
import Village from "./TheVillage.png";
import Kiwi from "./Kiwi.jpg";
import CarTower from "./CameroonTower.jpg";
import Meow from "./Meow.jpg";

export const paintings = [
  [Village, "The Village"],
  [Valley, "The Valley"],
  [Sea, "The Sea"],
  [Lake, "The Lake"],
  [Earth, "The Earth"],
  [Aco, "Shiba Aco"],
  [SnowMountain, "Snowy Mountains"],
  [Starlight, "Starlight Night"],
  [CanolaField, "Canola Flower Field"],
  [UnderTree, "Under The Tree"],
  [Kiwi, "Kiwi"],
  [CarTower, "Cameroon Tower"],
  [Meow, "Meow"],
];
