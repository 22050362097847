import TimeLoop from "./Time Loop.mp3";
import LittleRobin from "./Little Robin E major.mp3";
import DanceOnTheMoon from "./Dance on the Moon in D Dorian.mp3";
import Palatium from "./Palatium.mp3";
import LateSummerAfternoon from "./Late Summer Afternoon.mp3";

export const tracks = [
  ["Time Loop", TimeLoop, "3:31"],
  ["Little Robin", LittleRobin, "2:28"],
  ["Late Summer Afternoon", LateSummerAfternoon, "1:34"],
  ["Dance on the Moon", DanceOnTheMoon, "1:29"],
  ["Palatium", Palatium, "2:04"],
];
